/*
  @route    POST REACT_APP_API_URL/site/account/log-in
  @desc     Login an existing user
  @access   Public
*/

export async function login(formData) {
  // console.log(formData, "auth api 8");
  const data = new FormData();
  data.append("username", formData.username);
  data.append("password", formData.password);
  try {
    const url = `${process.env.REACT_APP_API_URL}/site/account/log-in`;
    const params = {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}
/*
  @route    POST REACT_APP_API_URL/site/account/log-in
  @desc     Login an existing user
  @access   Public
*/

export async function wwwLogin(formData) {
  // console.log(formData, "auth api 8");
  const data = new FormData();
  data.append("username", formData.username);
  data.append("password", formData.password);
  try {
    const url = `https://www.gueya.io/ajax/site/account/log-in`;
    const params = {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST REACT_APP_API_URL/site/account/sign-up
  @desc     Register a new user
  @access   Public
*/

export async function register(formData) {
  // console.log(formData, "auth api 37");
  const data = new FormData();
  data.append("link", formData.link);
  data.append("name", formData.name);
  data.append("last_name", formData.lastname);
  data.append("phone", formData.phone);
  data.append("email", formData.email);
  data.append("confirm_email", formData.emailConfirmation);
  data.append("username", formData.username);
  data.append("password", formData.password);
  data.append("confirm_passwork", formData.passwordConfirmation);
  try {
    const url = `${process.env.REACT_APP_API_URL}/site/account/sign-up`;
    const params = {
      method: "POST",
      mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST REACT_APP_API_URL/site/account/sign-up
  @desc     Register a new user
  @access   Public
*/

export async function wwwRegister(formData) {
  const data = new FormData();
  data.append("link", formData.link);
  data.append("name", formData.name);
  data.append("last_name", formData.lastname);
  data.append("phone", formData.phone);
  data.append("email", formData.email);
  data.append("confirm_email", formData.emailConfirmation);
  data.append("username", formData.username);
  data.append("password", formData.password);
  data.append("confirm_passwork", formData.passwordConfirmation);
  try {
    const url = `https://www.gueya.io/ajax/site/account/sign-up`;
    const params = {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST REACT_APP_API_URL/admin/account/get-profile
  @desc     Get Logged in User Profile
  @access   Public
*/

export async function getCurrentUserProfile(userId) {
  // console.log(userId);
  const data = new FormData();
  data.append("id_user", userId);
  try {
    const url = `${process.env.REACT_APP_API_URL}/admin/account/get-profile`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    // console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST https://www.gueya.io/ajax/admin/account/get-profile
  @desc     Get Logged in User Profile
  @access   Public
*/

export async function getWWWCurrentUserProfile(userId) {
  // console.log(userId);
  const data = new FormData();
  data.append("id_user", userId);
  try {
    const url = `https://www.gueya.io/ajax/admin/account/get-profile`;
    const params = {
      method: "POST",
      mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    // console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST REACT_APP_API_URL/site/profile/get-profile
  @desc     Get a
  @access   Public
*/

export async function getProfile(link) {
  // console.log(link);
  const data = new FormData();
  data.append("link", link);
  try {
    const url = `${process.env.REACT_APP_API_URL}/site/profile/get-profile`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST https://www.gueya.io/ajax/site/profile/get-profile
  @desc     Get a
  @access   Public
*/

export async function getWWWProfile(link) {
  // console.log(link);
  const data = new FormData();
  data.append("link", link);
  try {
    const url = `https://www.gueya.io/ajax/site/profile/get-profile`;
    const params = {
      method: "POST",
      mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    // console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    DELETE REACT_APP_API_URL/admin/images/profile/upload-img
  @desc     Delete an existing image
  @access   Public
*/

export async function deleteAvatarProfile(imageId) {
  // console.log(imageId);
  const data = new FormData();
  data.append("id_user", imageId);
  try {
    const url = `${process.env.REACT_APP_API_URL}/admin/images/profile/upload-img`;
    const params = {
      method: "DELETE",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: imageId,
    };
    const response = await fetch(url, params);
    // console.log(response, "response de auth.81");
    const result = await response.json();
    // console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST REACT_APP_API_URL/admin/uploads/images
  @desc     POST new image
  @access   Public
*/

export async function postAvatarProfile(image) {
  // console.log(image);
  const data = new FormData();
  // data.append("profile_img", { color: null });
  data.append("profile_img", image);
  try {
    const url = `${process.env.REACT_APP_API_URL}/admin/images/profile/upload-img`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    // console.log(response, "response de auth.178");
    const result = await response.json();
    // console.log(result, "result de auth.180");
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST https://www.gueya.io/ajax/admin/images/profile/upload-img`
  @desc     POST new image
  @access   Public
*/

export async function postWWWAvatarProfile(image) {
  // console.log(image);
  const data = new FormData();
  // data.append("profile_img", { color: null });
  data.append("profile_img", image);
  try {
    const url = `https://www.gueya.io/ajax/admin/images/profile/upload-img`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    // console.log(response, "response de auth.178");
    const result = await response.json();
    // console.log(result, "result de auth.180");
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST REACT_APP_API_URL/admin/account/edit-profile
  @desc     Update an existing profile
  @access   Public
*/

export async function updateProfile(formData) {
  const data = new FormData();
  data.append("profile[id_user]", formData.id_user);
  data.append("profile[name]", formData.name);
  data.append("profile[last_name]", formData.lastname);
  data.append("profile[email]", formData.email);
  data.append("profile[phone]", formData.phone);
  data.append("profile[company]", formData.businessName);
  data.append("profile[position]", formData.businessRole);
  data.append("profile[work_telephone]", formData.businessPhone);
  data.append("profile[ext]", formData.businessExt);
  data.append("profile[address]", formData.businessAddress);
  data.append("profile[colonia]", formData.businessNeighborhood);
  data.append("profile[estado]", formData.state);
  data.append("profile[country]", formData.country);
  data.append("profile[cp]", formData.postalCode);
  data.append("profile[web_page]", formData.businessWebsite);
  data.append("profile[facebook]", formData.facebookLink);
  data.append("profile[instagram]", formData.instagramLink);
  data.append("profile[tiktok]", formData.tikTokLink);
  data.append("profile[linkedin]", formData.linkedInLink);
  data.append("profile[img_profile_id]", formData.imgProfileId);
  data.append("id_user", formData.id_user);
  try {
    const url = `${process.env.REACT_APP_API_URL}/admin/account/edit-profile`;
    const params = {
      method: "POST",
      body: data,
    };
    const response = await fetch(url, params);
    // console.log(response, "response de auth.81");
    const result = await response.json();
    // console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST REACT_APP_API_URL/admin/account/edit-profile
  @desc     Update an existing profile
  @access   Public
*/

export async function updateWWWProfile(formData) {
  // console.log(formData);
  const data = new FormData();
  data.append("profile[id_user]", formData.id_user);
  data.append("profile[name]", formData.name);
  data.append("profile[last_name]", formData.lastname);
  data.append("profile[email]", formData.email);
  data.append("profile[phone]", formData.phone);
  data.append("profile[company]", formData.businessName);
  data.append("profile[position]", formData.businessRole);
  data.append("profile[work_telephone]", formData.businessPhone);
  data.append("profile[ext]", formData.businessExt);
  data.append("profile[address]", formData.businessAddress);
  data.append("profile[colonia]", formData.businessNeighborhood);
  data.append("profile[estado]", formData.state);
  data.append("profile[country]", formData.country);
  data.append("profile[cp]", formData.postalCode);
  data.append("profile[web_page]", formData.businessWebsite);
  data.append("profile[facebook]", formData.facebookLink);
  data.append("profile[instagram]", formData.instagramLink);
  data.append("profile[tiktok]", formData.tikTokLink);
  data.append("profile[linkedin]", formData.linkedInLink);
  data.append("profile[img_profile_id]", formData.imgProfileId);
  data.append("id_user", formData.id_user);
  try {
    const url = `https://www.gueya.io/ajax/admin/account/edit-profile`;
    const params = {
      method: "POST",
      body: data,
    };
    const response = await fetch(url, params);
    // console.log(response, "response de auth.81");
    const result = await response.json();
    // console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    GET REACT_APP_API_URL/admin/card-links/generate
  @desc     Generate Card links
  @access   Public
*/

export async function generateCardLinks() {
  try {
    const url = `${process.env.REACT_APP_API_URL}/admin/card-links/generate`;
    const params = {
      method: "GET",
      responseType: "blob",
      // headers: {
      //   "Content-Type": "application/json",
      // },
    };
    const response = await fetch(url, params);
    const result = await response.text();
    // console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST REACT_APP_API_URL/site/account/forgot-password
  @desc     POST to get email with token to reset password
  @access   Public
*/

export async function forgotPassword(email) {
  const data = new FormData();
  data.append("email", email);
  try {
    const url = `${process.env.REACT_APP_API_URL}/site/account/forgot-password`;
    const params = {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}
/*
  @route    POST REACT_APP_API_URL/site/account/forgot-password
  @desc     POST to get email with token to reset password
  @access   Public
*/

export async function forgotWWWPassword(email) {
  const data = new FormData();
  data.append("email", email);
  try {
    const url = `https://www.gueya.io/ajax/site/account/forgot-password`;
    const params = {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST REACT_APP_API_URL/site/account/reset-password
  @desc     POST to send new password with token
  @access   Public
*/

export async function resetPassword(values) {
  const data = new FormData();
  data.append("token", values.token);
  data.append("password", values.password);
  try {
    const url = `${process.env.REACT_APP_API_URL}/site/account/reset-password`;
    const params = {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}
/*
  @route    POST REACT_APP_API_URL/site/account/reset-password
  @desc     POST to send new password with token
  @access   Public
*/

export async function resetWWWPassword(values) {
  const data = new FormData();
  data.append("token", values.token);
  data.append("password", values.password);
  try {
    const url = `https://www.gueya.io/ajax/site/account/reset-password`;
    const params = {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: data,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}
